html.dark {
  background-color: rgb(15, 23, 42);
}
.q_cell {
  background-size: 54px 52px;
  --bg-angle: 90deg;
}
.q_present-hc {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--present-cell-bg-color) 50%
  );
}
.q_present-hc-dark {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--present-cell-bg-color) 50%
  );
}
.q_present {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--present-cell-bg-color) 50%
  );
}
.q_present-dark {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--present-cell-bg-color) 50%
  );
}
.q_correct-hc {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--correct-cell-bg-color) 50%
  );
}
.q_correct-hc-dark {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--correct-cell-bg-color) 50%
  );
}
.q_correct {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--correct-cell-bg-color) 50%
  );
}
.q_correct-dark {
  background-image: linear-gradient(
    var(--bg-angle),
    var(--absent-cell-bg-color) 50%,
    var(--correct-cell-bg-color) 50%
  );
}
